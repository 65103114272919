<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <bookmarks />
    </div>
    <div style="margin-right: 20px;font-weight: bold;padding-right: 5px;  border-right: 6px solid #0692f5;">Treasure Home - Tel: +43 1 9582335 , Email:Office@treasurehome.at</div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler class="d-none d-lg-block" />
<!--      <search-bar />-->
<!--      <hostaway-notification-dropdown />-->
      <notification-dropdown />
      <branches-dropdown/>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import BranchesDropdown from '@core/layouts/components/app-navbar/components/BranchesDropdown'
import HostawayNotificationDropdown from "@core/layouts/components/app-navbar/components/HostawayNotificationDropdown";

export default {
  components: {
    BranchesDropdown,
    BLink,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    // eslint-disable-next-line vue/no-unused-components
    SearchBar,
    DarkToggler,
/*
    HostawayNotificationDropdown,
*/
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
