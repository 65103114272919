<template>
    <b-nav-item-dropdown
        ref="notificationDropdown"
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >

      <template #button-content>
        <div>
          <feather-icon badge-classes="bg-danger" class="text-body" icon="SendIcon" size="21"/>
          <span class="badge badge-up badge-pill bg-primary" v-if="messages!=null">{{messages.length>0?messages.length:0}}</span>
        </div>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            {{ msg('Messages') }}
          </h4>
          <b-badge
              pill
              variant="light-primary"
          >
            {{messages!=null?messages.length:0}} {{ msg('New') }}
          </b-badge>
        </div>
      </li>

      <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
      >
        <template>
          <div style="color: black;"
               v-for="(message, index) in messages" :key="index" >
            <b-card
                :header="`Nachricht von:`"
                class="mb-1"
                @click="goToContract(message)"
                style="cursor: pointer;"
                :bg-variant="index % 2 === 0 ? 'primary' : 'secondary'"
            >
              <div class="notification-content">
                <b-badge :variant="index % 2 === 0 ? 'secondary' : 'primary'">Name: {{ message.customerName }}</b-badge>
                <p class="mt-1" style="color: black;font-weight: bold">{{ message.propertyAddress }}</p>
                <!-- Add other message details here -->
              </div>
            </b-card>
          </div>
        </template>
        <!--      <h4 v-else class="notification-title mb-0 p-2 text-center mr-auto">
                {{ msg('No new messages') }}
              </h4>-->
      </vue-perfect-scrollbar>

      <!--    &lt;!&ndash; Cart Footer &ndash;&gt;
          <li class="dropdown-menu-footer">
            <b-button
                variant="primary"
                block
                disabled
            >{{ msg('Read all messages') }}
            </b-button>
          </li>-->
    </b-nav-item-dropdown>

</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from "vuex";

export default {
  name: 'HostawayNotificationDropdown',
  components: { VuePerfectScrollbar },
  data() {
    return {
      messages: [],
      error: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
      },
      refreshInterval: null, // To store the setInterval reference
      dropdownVisible: false, // Track dropdown visibility
    };
  },
  created() {
    this.refresh(); // Initial call to refresh messages
    this.startAutoRefresh(); // Start automatic refresh
  },
  beforeDestroy() {
    this.stopAutoRefresh(); // Clear interval when component is destroyed
  },
  methods: {
    ...mapActions("hostaway", ["getAllMessages"]),

    // Method to navigate to contract
    goToContract(message) {
      this.$refs.notificationDropdown.hide();
      this.refresh(); // Initial call to refresh messages

      this.$router.push('/real_estate/contracts/rent/'+message.contractId)
    },

    // Refresh messages
    refresh() {
      this.getAllMessages()
          .then(this.onRetrieve)
          .catch(error => {
            this.error = error;
            console.error('Error fetching messages:', error);
          });
    },

    // Process retrieved data
    onRetrieve(data) {
      console.log('Messages retrieved:', data.messages);
      this.messages = data.messages || [];
    },

    // Start auto-refresh every 30 seconds
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        this.refresh();
      }, 30000); // 30 seconds in milliseconds
    },

    // Stop auto-refresh
    stopAutoRefresh() {
      clearInterval(this.refreshInterval);
    },

    // Event handler when dropdown is hidden
    dropdownHidden() {
      this.dropdownVisible = false;
    },
  }
};
</script>
<style>

</style>
