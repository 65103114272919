<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <div>
        <i class="far fa-building" style="font-size: 21px"></i>
        <span class="badge badge-up badge-pill bg-primary" v-if="selectedBranchesIds.length > 1">{{selectedBranchesIds.length}}</span>
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ msg('Branches') }}
        </h4>
        <b-badge pill variant="light-primary" @dblclick="toggleAllBranches">
          {{selectedBranchesIds.length}} {{msg("Branches")}}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >

      <b-link v-for="(branches,groupId) in allBranchesByGroup"  v-bind:key="groupId">
        <div class="media d-flex align-items-center text-capitalize  text-center" v-if="Object.keys(allGroups).length > 1">
          <h6 class="font-weight-bolder mr-auto mb-0  text-center w-100 d-inline-block">  {{allGroups[groupId]}} </h6>
        </div>

        <b-media v-for="branch in branches"  v-bind:key="'branch_'+branch.id" @click="selectBranchToggle(branch.id)">
          <template #aside>
            <b-avatar size="32" :ref="'avatar_'+branch.id" :variant="selectedBranchesVariant[branch.id]">
              <i class="far fa-building" style="font-size: 21px"></i>
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
               {{branch.name}}
            </span>
          </p>
          <small class="notification-text" v-if="branch.address != null && branch.address.trim().length > 0">{{branch.address}}</small>
          <small class="notification-text" v-else-if="branch.website != null && branch.website.trim().length > 0"> {{branch.website}}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    VuePerfectScrollbar,

  },
  data() {
    return {
      loaded:true,
    }
  },

  computed:{
    ...mapGetters('data', ['getSelectedBranchesIds']),
    ...mapGetters('data', {allBranchesByGroup: 'getAllBranchesByGroup',allBranches: 'getAllBranches', allGroups: 'getGroups'}),
    selectedBranchesIds: {
      get() {
        return this.getSelectedBranchesIds;
      },
      set(value) {
        this.setSelectedBranches(value)
      }
    },
    selectedBranchesVariant(){
      let selected = Object.values(this.allBranches).reduce((branches, branch)=>Object.assign(branches,{[branch.id]:'light-secondary'}),{});
      this.getSelectedBranchesIds.forEach(branchId=>selected[branchId] = 'light-primary');
      return selected;
    }
  },
  methods:{
    ...mapMutations('data', ['setSelectedBranches']),
    selectBranchToggle(branchId){
      console.log("selectBranchToggle")
      let selectedBranches = [...this.selectedBranchesIds];
      if(!selectedBranches.includes(branchId))
        selectedBranches.push(branchId);
      else if(selectedBranches.length<=1)
        return
      else
        selectedBranches = selectedBranches.filter(bId=>bId!==branchId);
      this.selectedBranchesIds = selectedBranches;
      this.updateAvatar(branchId);
    },
    toggleAllBranches(){
      console.log("toggleAllBranches", {selectedBranchesLength:this.selectedBranchesIds.length, allBranchesLength:Object.keys(this.allBranches).length, allBranches:Object.keys(this.allBranches),selectedBranchesIds:this.selectedBranchesIds})
      let allBranches = Object.values(this.allBranches).map(b=>b.id);
      if(this.selectedBranchesIds.length === Object.keys(this.allBranches).length)
        this.selectedBranchesIds = [ allBranches[0]];
      else
        this.selectedBranchesIds = allBranches;
      allBranches.forEach(branchId=>this.updateAvatar(branchId));
    },
    //Little hack in order to update the avatar
    updateAvatar(branchId){
      let $this = this;
      setTimeout(()=>{
        let element = $this.$refs['avatar_'+branchId][0].$el;
        element.classList.remove('badge-light-primary')
        element.classList.remove('badge-light-secondary')
        element.classList.add('badge-'+$this.selectedBranchesVariant[branchId])
      },100);
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
